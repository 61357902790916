import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlane, faDollarSign, faClock, faCheckCircle, faUsers } from '@fortawesome/free-solid-svg-icons'; // Import icons

const About = () => (
  <section className="bg-light py-1" style={{ fontFamily: 'Tahoma, sans-serif' }} id="about">
    <div className="container text-center">
      {/* Section Heading */}
      <h1 className=" mb-5" style={{ color: 'rgb(242, 146, 48)', fontFamily: 'Tahoma, sans-serif' }}>
        About Us
      </h1>
      <p className="lead mb-5" style={{ color: '#555', fontSize: '1.2rem', fontFamily: 'Tahoma, sans-serif' }}>
        At SILK ROAD GLOBAL LIMITED FOR COMMERCIAL AGENT LLC - SPC, we bring
        years of expertise and a customer-focused approach to the aviation industry.
        We are committed to delivering unparalleled service, providing high-quality aircraft parts
        sourced from leading manufacturers, and meeting the needs of our global clientele.
        Whether it’s airlines, MROs, or aircraft operators, our dedication to excellence sets us apart.
      </p>
    </div>
  </section>
);

export default About;
