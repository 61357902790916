// TermsLayout.js
import React from 'react';
import Header from '../Header';
import TermsAndPolicies from '../TermsAndPolicies';
import Footer from '../Footer';

const TermsLayout = () => (
  <div>
    <Header />
    <TermsAndPolicies />
    <Footer />
  </div>
);

export default TermsLayout;
