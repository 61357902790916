import './App.css';
import {React, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import FullLayout from './components/routes/FullLayout';
import TermsLayout from './components/routes/TermsLayout';
import ReactGA from "react-ga4";

const TRACKING_ID = "G-GFPHKTWQDM";
ReactGA.initialize(TRACKING_ID);

const App = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);

    return(
      <Router>
        <Routes>
          {/* Route for Terms and Policies */}
          <Route exact path="/terms-and-policies" element={<TermsLayout />} />

          {/* Route for Home or root (/) */}
          <Route exact  path="/" element={<FullLayout />} />
        </Routes>
      </Router>
    );
}

export default App;
