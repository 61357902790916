import React, { useState, useEffect } from 'react';
import Navbar from './Navbar'; // Import the Navbar component
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './styles.css'; // Import the custom CSS file for styles and animations

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to toggle menu
  const [isOverlayVisible, setIsOverlayVisible] = useState(false); // State to toggle overlay
  const [showScrollButtons, setShowScrollButtons] = useState({ up: false, down: true });

  // Toggle the menu visibility and overlay background
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsOverlayVisible(!isOverlayVisible); // Show overlay when menu is open
  };

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Scroll to bottom function
  const scrollToBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  };

  // Update scroll button visibility based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      if (scrollTop === 0) {
        setShowScrollButtons({ up: false, down: true });
      } else if (scrollTop + windowHeight >= documentHeight) {
        setShowScrollButtons({ up: true, down: false });
      } else {
        setShowScrollButtons({ up: true, down: true });
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      {/* Use the Navbar Component */}
      <Navbar toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />

      {/* Header Section */}
      <header
        style={{
          position: 'relative',
          height: '100vh', // Ensure the header takes full screen height
          backgroundImage: 'url("/air.webp")',
          backgroundPosition: 'center',
          backgroundSize: 'cover', // Ensure the image fills the screen while maintaining aspect ratio
          color: '#fff',
          overflow: 'hidden',
          paddingTop: '80px', // Add padding to compensate for navbar height
        }}
      >
        {/* Hero Content */}
        <div
          className="hero-content"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            height: '100%',
            padding: '0 20px',
            position: 'relative',
            zIndex: 1,
          }}
        >
          <h2
            style={{
              fontFamily: 'Tahoma, sans-serif',
              fontSize: '2rem',
              fontWeight: 'bold',
              marginBottom: '20px',
              color: '#F29230',
              textShadow: '4px 4px 12px rgba(0, 0, 0, 0.7)',
            }}
          >
            SILK ROAD GLOBAL LIMITED FOR COMMERCIAL AGENT LLC - SPC
          </h2>
          <p
            style={{
              fontSize: '1.5rem',
              lineHeight: '2',
              fontFamily: 'Tahoma, sans-serif',
              marginBottom: '40px',
              color: '#fff',
              maxWidth: '900px',
              textShadow: 'rgba(0, 0, 0, 10) 2px 2px 2px',
            }}
          >
            Your Premier Partner for Aircraft Parts and Aviation Excellence
          </p>
        </div>
      </header>

      {/* Overlay background when menu is open */}
      <div
        className={`overlay ${isOverlayVisible ? 'show' : ''}`}
        onClick={toggleMenu} // Close the menu when overlay is clicked
      ></div>

      {/* Scroll Buttons */}
      <div
        style={{
          position: 'fixed',
          bottom: '20px',
          left: '20px',
          zIndex: 1000,
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        {showScrollButtons.up && (
          <button
            onClick={scrollToTop}
            className="btn btn-warning"
            style={{
              backgroundColor: '#F29230',
              color: '#fff',
              border: 'none',
              borderRadius: '50%',
              width: '50px',
              height: '50px',
              transition: 'transform 0.3s ease',
              boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
            }}
            onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
            onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
          >
            ↑
          </button>
        )}

        {showScrollButtons.down && (
          <button
            onClick={scrollToBottom}
            className="btn btn-warning"
            style={{
              backgroundColor: '#F29230',
              color: '#fff',
              border: 'none',
              borderRadius: '50%',
              width: '50px',
              height: '50px',
              transition: 'transform 0.3s ease',
              boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
            }}
            onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
            onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
          >
            ↓
          </button>
        )}
      </div>
    </div>
  );
};

export default Header;
