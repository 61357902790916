import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

const Footer = () => (
  <footer
    style={{
      backgroundColor: '#ffffff',
      borderTop: '6px dashed #F29230',
      color: '#fff',
      fontSize: '0.9rem',
      padding: '40px 20px 20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center', // Vertically center
      alignItems: 'center', // Horizontally center
      height: 'auto', // Allow it to grow based on content
      fontFamily: 'Tahoma, sans-serif', // Apply Tahoma font here
    }}
  >

    <div
      style={{
        paddingTop: '20px',
        textAlign: '-webkit-center',
        color: '#F29230',
        width: '-webkit-fill-available',
      }}
    >
      <p style={{ marginBottom: '5px', fontSize: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        © 2024, Silk Road Global
        <a
          href="/terms-and-policies"
          style={{ marginLeft: '5px', color: '#F29230', textDecoration: 'none' }} // Custom styling
          onMouseEnter={(e) => e.target.style.color = '#10b981'} // Hover effect
          onMouseLeave={(e) => e.target.style.color = '#F29230'} // Reset hover effect
        >
          Terms & Policies
        </a>
      </p>

      <p style={{ margin: '0', fontSize: '1rem' }}>
        Developed by <span style={{ color: '#F29230', fontSize: '1rem' }}>Xcode Team</span>
      </p>
    </div>
  </footer>
);

export default Footer;
