import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const Home = () => (
  <section className="bg-light py-5" style={{ fontFamily: 'Tahoma, sans-serif' }} id="home">
    <div className="container text-center">
      {/* Title Section */}
      <h1 className="mb-5" style={{ color: '#F29230', fontFamily: 'Tahoma, sans-serif' }}>
        Welcome to SILK ROAD GLOBAL LIMITED FOR COMMERCIAL AGENT LLC - SPC
      </h1>
      <p className="lead mb-5" style={{ color: '#555', fontSize: '1.2rem', fontFamily: 'Tahoma, sans-serif' }}>
        We are your trusted partner for premium aircraft parts and aviation excellence. With an
        extensive inventory, competitive pricing, and fast delivery, we serve airlines, MRO
        facilities, and aircraft operators worldwide. Safety and quality are our priorities,
        ensuring reliability and performance in every product we deliver.
      </p>
    </div>
  </section>
);

export default Home;
