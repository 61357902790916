import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlane, faDollarSign, faClock, faCheckCircle, faUsers } from '@fortawesome/free-solid-svg-icons'; // Import icons

const Stakeholders = () => (
  <section className="bg-light py-5" style={{ fontFamily: 'Tahoma, sans-serif' }}>
    <div className="container">
      {/* Partners Section */}
      <h1
        className="text-center mt-5 mb-4"
        style={{ color: 'rgb(242, 146, 48)', fontFamily: 'Tahoma, sans-serif' }}
        id = "partners"
      >
        Our Partners
      </h1>
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
        {/* Michelin */}
        <div className="col">
          <div className="card shadow-sm border-light rounded p-3 h-100">
            <img
              src="/Michelin-Symbol.png"
              alt="Michelin logo"
              className="card-img-top mb-3"
              style={{ maxWidth: '180px', height: 'auto', margin: '0 auto' }}
            />
            <p className="card-text" style={{ color: '#555', fontSize: '1rem', fontFamily: 'Tahoma, sans-serif' }}>
              Innovative and durable aircraft tires designed for superior performance and longevity.
            </p>
          </div>
        </div>
        {/* Dunlop Aircraft Tyres */}
        <div className="col">
          <div className="card shadow-sm border-light rounded p-3 h-100">
            <img
              src="/Dunlop-Aircraft-Tyres.png"
              alt="Dunlop Aircraft Tyres logo"
              className="card-img-top mb-3"
              style={{ maxWidth: '180px', height: 'auto', margin: '0 auto' }}
            />
            <p className="card-text" style={{ color: '#555', fontSize: '1rem', fontFamily: 'Tahoma, sans-serif' }}>
              Specialists in tire design and retreading, offering long-lasting solutions.
            </p>
          </div>
        </div>
        {/* Goodyear */}
        <div className="col">
          <div className="card shadow-sm border-light rounded p-3 h-100">
            <img
              src="/R.png"
              alt="Goodyear logo"
              className="card-img-top mb-3"
              style={{ maxWidth: '180px', height: 'auto', margin: '0 auto' }}
            />
            <p className="card-text" style={{ color: '#555', fontSize: '1rem', fontFamily: 'Tahoma, sans-serif' }}>
              Renowned for strength and reliability, providing tires that ensure safety and durability.
            </p>
          </div>
        </div>
        {/* Bridgestone */}
        <div className="col">
          <div className="card shadow-sm border-light rounded p-3 h-100">
            <img
              src="/bridgestone1.png"
              alt="Bridgestone logo"
              className="card-img-top mb-3"
              style={{ maxWidth: '180px', height: 'auto', margin: '0 auto' }}
            />
            <p className="card-text" style={{ color: '#555', fontSize: '1rem', fontFamily: 'Tahoma, sans-serif' }}>
              High-quality solutions for aircraft tires, providing exceptional performance across all sectors.
            </p>
          </div>
        </div>
      </div>
       <h1 className="text-center mt-5 mb-4" style={{ color: 'rgb(242, 146, 48)', fontFamily: 'Tahoma, sans-serif' }}>Why Choose Silk Road Global ?</h1>

            {/* Cards displaying the "Why Choose Us?" features */}
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
              {/* Card 1 - Extensive Inventory */}
              <div className="col">
                <div className="card h-100 shadow-lg border-light rounded card-hover">
                  <div className="card-body text-center">
                    <FontAwesomeIcon icon={faPlane} size="3x" className="mb-3" style={{ color: '#F29230' }} />
                    <h5 className="card-title" style={{ color: 'rgb(242, 146, 48)', fontFamily: 'Tahoma, sans-serif' }}>Extensive Inventory</h5>
                    <p className="card-text" style={{ fontFamily: 'Tahoma, sans-serif' }}>
                      Find new, refurbished, or hard-to-find parts with ease. Our extensive inventory ensures you can
                      get what you need quickly and efficiently.
                    </p>
                  </div>
                </div>
              </div>
{/* Card 2 - Competitive Pricing */}
        <div className="col">
          <div className="card h-100 shadow-lg border-light rounded card-hover">
            <div className="card-body text-center">
              <FontAwesomeIcon icon={faDollarSign} size="3x" className="mb-3" style={{ color: '#F29230' }} />
              <h5 className="card-title" style={{ color: 'rgb(242, 146, 48)', fontFamily: 'Tahoma, sans-serif' }}>Competitive Pricing</h5>
              <p className="card-text" style={{ fontFamily: 'Tahoma, sans-serif' }}>
                Quality aviation parts at exceptional value. We offer competitive pricing without compromising
                on quality.
              </p>
            </div>
          </div>
        </div>

        {/* Card 3 - Fast Delivery */}
        <div className="col">
          <div className="card h-100 shadow-lg border-light rounded card-hover">
            <div className="card-body text-center">
              <FontAwesomeIcon icon={faClock} size="3x" className="mb-3" style={{ color: '#F29230' }} />
              <h5 className="card-title" style={{ color: 'rgb(242, 146, 48)', fontFamily: 'Tahoma, sans-serif' }}>Fast Delivery</h5>
              <p className="card-text" style={{ fontFamily: 'Tahoma, sans-serif' }}>
                Minimize downtime with our prompt global delivery service. We get parts to you quickly, wherever you are.
              </p>
            </div>
          </div>
        </div>

        {/* Card 4 - Quality Assurance */}
        <div className="col">
          <div className="card h-100 shadow-lg border-light rounded card-hover">
            <div className="card-body text-center">
              <FontAwesomeIcon icon={faCheckCircle} size="3x" className="mb-3" style={{ color: '#F29230' }} />
              <h5 className="card-title" style={{ color: 'rgb(242, 146, 48)', fontFamily: 'Tahoma, sans-serif' }}>Quality Assurance</h5>
              <p className="card-text" style={{ fontFamily: 'Tahoma, sans-serif' }}>
                Rigorously tested, fully traceable, and compliant with industry standards. We ensure every part meets the highest quality standards.
              </p>
            </div>
          </div>
        </div>

        {/* Card 5 - Customer-Centric Service */}
        <div className="col">
          <div className="card h-100 shadow-lg border-light rounded card-hover">
            <div className="card-body text-center">
              <FontAwesomeIcon icon={faUsers} size="3x" className="mb-3" style={{ color: '#F29230' }} />
              <h5 className="card-title" style={{ color: 'rgb(242, 146, 48)', fontFamily: 'Tahoma, sans-serif' }}>Customer-Centric Service</h5>
              <p className="card-text" style={{ fontFamily: 'Tahoma, sans-serif' }}>
                Personalized solutions tailored to your goals. We work closely with each client to meet their specific needs and objectives.
              </p>
            </div>
          </div>
        </div>




            </div>
    </div>

  </section>
);

export default Stakeholders;