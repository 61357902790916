import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const TermsAndPolicies = () => (
  <section style={{ backgroundColor: '#ffffff', padding: '60px 20px' }}>
    <div className="container text-center">
      <h2 className="display-4 mb-5" style={{ color: '#F29230' }}>
        Terms & Policies
      </h2>

      <div className="row">

        {/* Terms and Conditions */}
        <div className="col-lg-6 mb-4">
          <h3 className="mb-4" style={{ color: 'rgb(242, 146, 48)' }}>1. Terms and Conditions</h3>
          <ul style={{ textAlign: 'left', color: '#555', fontSize: '1.1rem' }}>
            <li><strong>Acceptance of Terms:</strong> By using the website, users agree to the terms.</li>
            <li><strong>Use of Website:</strong> Rules about permissible use and prohibited activities.</li>
            <li><strong>Intellectual Property Rights:</strong> Ownership of content, trademarks, logos, etc.</li>
            <li><strong>Liability Limitations:</strong> Disclaimer about responsibility for errors, website downtime, etc.</li>
            <li><strong>Governing Law:</strong> Indicate UAE laws govern disputes.</li>
          </ul>
        </div>

        {/* Privacy Policy */}
        <div className="col-lg-6 mb-4">
          <h3 className="mb-4" style={{ color: 'rgb(242, 146, 48)' }}>2. Privacy Policy</h3>
          <ul style={{ textAlign: 'left', color: '#555', fontSize: '1.1rem' }}>
            <li><strong>Data Collection:</strong> Explain what data you collect (e.g., name, email, IP address).</li>
            <li><strong>Use of Data:</strong> Specify how you use collected data (e.g., to improve services or for marketing).</li>
            <li><strong>Third-Party Sharing:</strong> Disclose whether data is shared with other entities.</li>
            <li><strong>User Rights:</strong> Provide users with options to access, modify, or delete their data.</li>
            <li><strong>Cookies:</strong> Explain how cookies are used on the site.</li>
          </ul>
        </div>

        {/* Legal Disclaimer */}
        <div className="col-lg-6 mb-4">
          <h3 className="mb-4" style={{ color: 'rgb(242, 146, 48)' }}>3. Legal Disclaimer</h3>
          <ul style={{ textAlign: 'left', color: '#555', fontSize: '1.1rem' }}>
            <li><strong>Accuracy of Information:</strong> Acknowledge that while you aim for accuracy, errors may occur.</li>
            <li><strong>Third-Party Links:</strong> State that you’re not responsible for external links or services.</li>
            <li><strong>Service Availability:</strong> Mention that services might change without prior notice.</li>
          </ul>
        </div>

        {/* Licensing and Compliance */}
        <div className="col-lg-6 mb-4">
          <h3 className="mb-4" style={{ color: 'rgb(242, 146, 48)' }}>4. Licensing and Compliance</h3>
          <ul style={{ textAlign: 'left', color: '#555', fontSize: '1.1rem' }}>
            <li><strong>Trade License:</strong> Mention your trade license prominently (License No. 1022822).</li>
            <li><strong>Federal Registration Number:</strong> Include the Federal Registration number (12070823).</li>
            <li><strong>Regulatory Compliance:</strong> Compliance with UAE’s Commercial Companies Law and other relevant regulations.</li>
          </ul>
        </div>

        {/* Accessibility Statement */}
        <div className="col-lg-6 mb-4">
          <h3 className="mb-4" style={{ color: 'rgb(242, 146, 48)' }}>5. Accessibility Statement</h3>
          <p style={{ color: '#555', fontSize: '1.1rem' }}>
            Our website is designed to be usable by people with disabilities, meeting international accessibility standards.
          </p>
        </div>

        {/* Refund and Cancellation Policy */}
        <div className="col-lg-6 mb-4">
          <h3 className="mb-4" style={{ color: 'rgb(242, 146, 48)' }}>6. Refund and Cancellation Policy</h3>
          <ul style={{ textAlign: 'left', color: '#555', fontSize: '1.1rem' }}>
            <li><strong>Conditions:</strong> Conditions for refunds, returns, and cancellations.</li>
            <li><strong>Processing Timeframes:</strong> Processing timeframes and methods for refunds or cancellations.</li>
            <li><strong>Contact Details:</strong> Contact details for disputes or claims.</li>
          </ul>
        </div>

        {/* Contact and Support Information */}
        <div className="col-lg-6 mb-4">
          <h3 className="mb-4" style={{ color: 'rgb(242, 146, 48)' }}>7. Contact and Support Information</h3>
          <ul style={{ textAlign: 'left', color: '#555', fontSize: '1.1rem' }}>
            <li><strong>Physical Address:</strong> Your physical address here.</li>
            <li><strong>Phone Number:</strong> Your phone number here.</li>
            <li><strong>Customer Support Email:</strong> Support email here.</li>
            <li><strong>Working Hours:</strong> Your working hours for support.</li>
          </ul>
        </div>

        {/* Regulatory and Tax Information */}
        <div className="col-lg-6 mb-4">
          <h3 className="mb-4" style={{ color: 'rgb(242, 146, 48)' }}>8. Regulatory and Tax Information</h3>
          <ul style={{ textAlign: 'left', color: '#555', fontSize: '1.1rem' }}>
            <li><strong>VAT Compliance:</strong> If VAT is applicable, include your VAT registration number.</li>
            <li><strong>Import/Export Licenses:</strong> Highlight compliance with UAE customs regulations.</li>
          </ul>
        </div>

        {/* Anti-Fraud and Anti-Money Laundering Policy */}
        <div className="col-lg-6 mb-4">
          <h3 className="mb-4" style={{ color: 'rgb(242, 146, 48)' }}>9. Anti-Fraud and Anti-Money Laundering Policy</h3>
          <ul style={{ textAlign: 'left', color: '#555', fontSize: '1.1rem' }}>
            <li><strong>Transaction Screening:</strong> Screening transactions against sanctioned parties.</li>
            <li><strong>Due Diligence:</strong> Maintaining due diligence for high-value transactions.</li>
          </ul>
        </div>

      </div>
    </div>
  </section>
);

export default TermsAndPolicies;
