import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './styles.css';

const Navbar = ({ toggleMenu, isMenuOpen }) => {
  const navigate = useNavigate();
  const [activeDropdown, setActiveDropdown] = useState(null); // State to track active dropdown

  const handleNavigation = (route, sectionId) => {
    navigate(route);
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 0);
  };

  const menuItems = [
    { text: 'Home', route: '/', section: 'home' },
    { text: 'About Us', route: '/', section: 'about' },
    {
      text: 'Services',
      dropdown: true,
      subItems: [
        { text: 'Aircraft', route: '/', section: 'aircraft' },
        { text: 'Heavy Equipment Spare Parts', route: '/', section: 'heavy-equipment' },
        { text: 'Oil and Gas', route: '/', section: 'oil-gas' },
      ],
    },
    { text: 'Our Partners', route: '/', section: 'partners' },
    { text: 'Contact', route: '/', section: 'contact' },
  ];

  return (
    <nav className="navbar navbar-expand-lg navbar-light fixed-top creative-navbar">
      {/* Logo */}
      <a className="navbar-brand" href="/">
        <img
          src="/logo_transparent.png"
          alt="Logo"
          className="navbar-logo"
          style={{ maxWidth: '350px', height: 'auto' }}
        />
      </a>

      {/* Hamburger Button */}
      <button
        className={`navbar-toggler hamburger-button ${isMenuOpen ? 'open' : ''}`}
        type="button"
        aria-expanded={isMenuOpen ? 'true' : 'false'}
        aria-label="Toggle navigation"
        onClick={toggleMenu}
      >
        <div className="hamburger-icon">
          <span className={`bar ${isMenuOpen ? 'rotate-top' : ''}`} style={{ backgroundColor: '#F29230' }}></span>
          <span className={`bar ${isMenuOpen ? 'fade-out' : ''}`} style={{ backgroundColor: '#F29230' }}></span>
          <span className={`bar ${isMenuOpen ? 'rotate-bottom' : ''}`} style={{ backgroundColor: '#F29230' }}></span>
        </div>
      </button>

      {/* Navigation Links */}
      <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarNav">
        <ul className="navbar-nav ms-auto">
          {menuItems.map((item, index) => (
            <li key={index} className={`nav-item ${item.dropdown ? 'dropdown' : ''}`}>
              {item.dropdown ? (
                <div className="dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id={`dropdown-${index}`}
                    role="button"
                    aria-expanded={activeDropdown === index ? 'true' : 'false'}
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveDropdown(activeDropdown === index ? null : index);
                    }}
                  >
                    {item.text}
                  </a>
                  <ul className={`dropdown-menu ${activeDropdown === index ? 'show' : ''} shadow-lg border-0 rounded-3 p-3`}>
                    {item.subItems.map((subItem, subIndex) => (
                      <li key={subIndex}>
                        <a
                          className="dropdown-item py-2 px-3 text-dark fw-bold"
                          href={`#${subItem.section}`}
                          onClick={(e) => {
                            e.preventDefault();
                            handleNavigation(subItem.route, subItem.section);
                            setActiveDropdown(null); // Close dropdown after selection
                          }}
                        >
                          {subItem.text}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <a
                  className="nav-link"
                  href={`#${item.section}`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleNavigation(item.route, item.section);
                  }}
                >
                  {item.text}
                </a>
              )}
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
