// FullLayout.js
import React from 'react';
import Header from '../Header';
import Navbar from '../Navbar';
import Home from '../Home';
import About from '../About';
import Services from '../Services';
import Stakeholders from '../Stakeholders';
import TermsAndPolicies from '../TermsAndPolicies';
import Contact from '../Contact';
import Footer from '../Footer';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const FullLayout = () => (
  <div>
    <Header />
    <Home />
    <About />
    <Services />
    <Stakeholders />
    <Contact />
    <Footer />
  </div>
);

export default FullLayout;
