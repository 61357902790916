import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWrench,
  faTachometerAlt,
  faTools,
  faFileAlt,
  faArrowUp,
  faCogs,
  faHandsHelping,
} from "@fortawesome/free-solid-svg-icons"; // Icons for the services

const Services = () => (
  <section
    className="bg-light py-1"
    id="services"
    style={{ fontFamily: "Tahoma, sans-serif" }}
  >
    <div className="container">
      <h1 className="text-center mb-5" style={{ color: "rgb(242, 146, 48)" }}>
        Services
      </h1>

      <h1
        className="text-center mt-5 mb-4"
        style={{ color: "rgb(242, 146, 48)", fontFamily: "Tahoma, sans-serif" }}
        id = "aircraft"
      >
        Aircraft
      </h1>

      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
        {/* Engine Components */}
        <div className="col">
          <div className="card h-100 text-center border-0 shadow">
            <div className="card-body">
              <FontAwesomeIcon
                icon={faWrench}
                className="mb-4"
                style={{ fontSize: "60px", color: "#F29230" }}
              />
              <h4 className="card-title" style={{ color: "rgb(242, 146, 48)" }}>
                Engine Components
              </h4>
              <p
                className="card-text"
                style={{ fontSize: "1.1rem", color: "#555" }}
              >
                We provide precision-engineered engine components, including
                turbine blades, fuel nozzles, and bearings, to ensure top-tier
                performance and reliability for your aircraft.
              </p>
            </div>
          </div>
        </div>

        {/* Airframe Parts */}
        <div className="col">
          <div className="card h-100 text-center border-0 shadow">
            <div className="card-body">
              <FontAwesomeIcon
                icon={faTachometerAlt}
                className="mb-4"
                style={{ fontSize: "60px", color: "#F29230" }}
              />
              <h4 className="card-title" style={{ color: "rgb(242, 146, 48)" }}>
                Airframe Parts
              </h4>
              <p
                className="card-text"
                style={{ fontSize: "1.1rem", color: "#555" }}
              >
                Our selection of airframe parts, such as landing gear systems,
                control surfaces, and fuselage elements, is designed to maintain
                structural integrity and operational excellence.
              </p>
            </div>
          </div>
        </div>

        {/* Avionics */}
        <div className="col">
          <div className="card h-100 text-center border-0 shadow">
            <div className="card-body">
              <FontAwesomeIcon
                icon={faTools}
                className="mb-4"
                style={{ fontSize: "60px", color: "#F29230" }}
              />
              <h4 className="card-title" style={{ color: "rgb(242, 146, 48)" }}>
                Avionics
              </h4>
              <p
                className="card-text"
                style={{ fontSize: "1.1rem", color: "#555" }}
              >
                Equip your fleet with advanced avionics, including navigation
                systems, communication tools, and instruments, for enhanced
                safety and efficiency.
              </p>
            </div>
          </div>
        </div>

        {/* Consumables and Expendables */}
        <div className="col">
          <div className="card h-100 text-center border-0 shadow">
            <div className="card-body">
              <FontAwesomeIcon
                icon={faFileAlt}
                className="mb-4"
                style={{ fontSize: "60px", color: "#F29230" }}
              />
              <h4 className="card-title" style={{ color: "rgb(242, 146, 48)" }}>
                Consumables and Expendables
              </h4>
              <p
                className="card-text"
                style={{ fontSize: "1.1rem", color: "#555" }}
              >
                We stock a wide range of essential consumables, from fasteners
                and filters to safety equipment, ensuring convenience and
                readiness for daily operations.
              </p>
            </div>
          </div>
        </div>

        {/* Upgrades and Modifications */}
        <div className="col">
          <div className="card h-100 text-center border-0 shadow">
            <div className="card-body">
              <FontAwesomeIcon
                icon={faArrowUp}
                className="mb-4"
                style={{ fontSize: "60px", color: "#F29230" }}
              />
              <h4 className="card-title" style={{ color: "rgb(242, 146, 48)" }}>
                Upgrades and Modifications
              </h4>
              <p
                className="card-text"
                style={{ fontSize: "1.1rem", color: "#555" }}
              >
                Boost your aircraft’s capabilities with tailored upgrades and
                modifications, including performance enhancements and system
                integrations.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Heavy Equipment Spare Parts Section */}
      <h1
        className="text-center mt-5 mb-4"
        style={{ color: "rgb(242, 146, 48)", fontFamily: "Tahoma, sans-serif" }}
         id="heavy-equipment"
      >
        Heavy Equipment Spare Parts
      </h1>
      {/* First Point - Text Left, Image Right */}
      <div className="row border p-2 align-items-center mb-5">
        <div className="col-md-6 mb-3 mb-md-0 d-flex align-items-center">
          <p
            className="lead"
            style={{
              color: "#555",
              fontSize: "1.2rem",
              fontFamily: "Tahoma, sans-serif",
            }}
          >
            Our company specializes in providing top-quality heavy equipment and
            genuine parts for marina and industrial applications. With years of
            experience, our team understands the importance of reliable
            equipment and prompt service, ensuring our clients operations run
            smoothly and efficiently.
          </p>
        </div>
        <div className="col-md-6 text-center">
          <img
            src="/dumper2.webp" // Replace with your actual image path
            alt="Aircraft Tires"
            className="img-fluid rounded border shadow"
            style={{
              maxWidth: "100%",
              maxHeight: "400px",
              objectFit: "contain",
            }}
          />
        </div>
      </div>

      {/* Second Point - Image Left, Text Right */}
      <div className="row border p-1 align-items-center mb-5">
        <div className="col-md-6 text-center mb-3 mb-md-0">
          <img
            src="/m3-Photoroom.png"
            alt="Tire Inspection"
            className="img-fluid rounded border shadow"
            style={{
              maxWidth: "100%",
              maxHeight: "400px",
              objectFit: "contain",
            }}
          />
        </div>
        <div className="col-md-6 d-flex align-items-center">
          <p
            className="lead"
            style={{
              color: "#555",
              fontSize: "1.2rem",
              fontFamily: "Tahoma, sans-serif",
            }}
          >
            We offer comprehensive services to support your marina and
            industrial equipment needs, including equipment rental, maintenance
            and repair, genuine parts supply, and expert consultation and
            training. Our experienced team provides prompt, reliable and
            cost-effective solutions to minimize downtime and maximize
            productivity.
          </p>
        </div>
      </div>

      {/* Oil and Gas Section */}
      <h1
        className="text-center mt-5 mb-4"
        style={{ color: "rgb(242, 146, 48)", fontFamily: "Tahoma, sans-serif" }}
        id="oil-gas"
      >
        Oil and Gas
      </h1>

      <div className="row border p-2 align-items-center mb-5 card mb-3">
      <img src="/Jackup_Rigs.webp" class="card-img-top" alt="..."/>
       <div class="card-body">
          <h5 class="card-title"></h5>
        </div>
        <div className="col-md-12 mb-3 mb-md-0">
          <p
            className="lead text-center"
            style={{
              color: "#555",
              fontSize: "1.2rem",
              fontFamily: "Tahoma, sans-serif",
            }}
          >
            At SILK ROAD GLOBAL LIMITED FOR COMMERCIAL AGENT LLC - SPC, we're
            passionate about powering the oil and gas industry forward. Our team
            of experts has years of experience in providing top-notch equipment
            solutions for upstream, midstream, and downstream operations. We
            pride ourselves on delivering reliable, efficient, and safe
            equipment to keep your business running smoothly.
          </p>
        </div>
      </div>

      <div className="row row-cols-1 row-cols-md-2 g-4">
              <div className="col">
                <div className="card h-100 text-center border-0 shadow">
                  <div className="card-body">
                    <FontAwesomeIcon
                      icon={faHandsHelping}
                      className="mb-4"
                      style={{ fontSize: "60px", color: "#F29230" }}
                    />
                    <h4 className="card-title" style={{ color: "rgb(242, 146, 48)" }}>
                      Tailored Services
                    </h4>
                    <ul
                      className="list-unstyled"
                      style={{ fontSize: "1.1rem", color: "#555" }}
                    >
                      <li>1. Equipment rental and sales</li>
                      <li>2. Maintenance and repair services</li>
                      <li>3. Genuine parts supply</li>
                      <li>4. Expert consultation and training</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="card h-100 text-center border-0 shadow">
                  <div className="card-body">
                    <FontAwesomeIcon
                      icon={faCogs}
                      className="mb-4"
                      style={{ fontSize: "60px", color: "#F29230" }}
                    />
                    <h4 className="card-title" style={{ color: "rgb(242, 146, 48)" }}>
                      Equipment Solutions
                    </h4>
                    <ul
                      className="list-unstyled"
                      style={{ fontSize: "1.1rem", color: "#555" }}
                    >
                      <li>1. Drilling and extraction gear</li>
                      <li>2. Processing and refining machinery</li>
                      <li>3. Pipeline and transportation essentials</li>
                      <li>4. Safety and inspection tools</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
    </div>
  </section>
);

export default Services;
