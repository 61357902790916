import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faIdCard, faBuilding, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

const Contact = () => (
  <section className="bg-light py-5" style={{ fontFamily: 'Tahoma, sans-serif' }} id="contact">
    <div
      style={{
        maxWidth: '800px',
        margin: '0 auto',
        textAlign: 'center',
      }}
    >
      <h1 className=" mb-5" style={{ color: 'rgb(242, 146, 48)', fontFamily: 'Tahoma, sans-serif' }}>
        Contact us
      </h1>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', // Responsive grid
          gap: '30px',
        }}
      >
        {/* Phone Card */}
        <div
          style={{
            backgroundColor: '#ffffff',
            borderRadius: '10px',
            padding: '30px',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
            textAlign: 'center',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'scale(1.05)';
            e.currentTarget.style.boxShadow = '0 6px 15px rgba(0, 0, 0, 0.15)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'scale(1)';
            e.currentTarget.style.boxShadow = '0 4px 10px rgba(0, 0, 0, 0.1)';
          }}
        >
          <FontAwesomeIcon
            icon={faPhone}
            style={{
              fontSize: '50px',
              color: '#4f46e5',
              marginBottom: '20px',
            }}
          />
          <h3 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '10px', color: '#333' }}>
            Phone
          </h3>
          <a
            href="tel:+97192234333"
            style={{
              fontSize: '1.2rem',
              color: '#555',
              textDecoration: 'none',
              lineHeight: '1.6',
            }}
          >
            +971 92234333
          </a>
        </div>

        {/* Email Card */}
        <div
          style={{
            backgroundColor: '#ffffff',
            borderRadius: '10px',
            padding: '30px',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
            textAlign: 'center',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'scale(1.05)';
            e.currentTarget.style.boxShadow = '0 6px 15px rgba(0, 0, 0, 0.15)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'scale(1)';
            e.currentTarget.style.boxShadow = '0 4px 10px rgba(0, 0, 0, 0.1)';
          }}
        >
          <FontAwesomeIcon
            icon={faEnvelope}
            style={{
              fontSize: '50px',
              color: '#4f46e5',
              marginBottom: '20px',
            }}
          />
          <h3 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '10px', color: '#333' }}>
            Email
          </h3>
          <a
            href="mailto:info@silkroadglobal.com"
            style={{
              fontSize: '1.2rem',
              color: '#555',
              textDecoration: 'none',
              lineHeight: '1.6',
            }}
          >
            info@silkroadglobal.ae
          </a>
        </div>

        {/* Address Card */}
        <div
          style={{
            backgroundColor: '#ffffff',
            borderRadius: '10px',
            padding: '30px',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
            textAlign: 'center',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'scale(1.05)';
            e.currentTarget.style.boxShadow = '0 6px 15px rgba(0, 0, 0, 0.15)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'scale(1)';
            e.currentTarget.style.boxShadow = '0 4px 10px rgba(0, 0, 0, 0.1)';
          }}
        >
         <a
            href="https://maps.app.goo.gl/SKZaBmKRbdE8LHcw8?g_st=com.google.maps.preview.copy"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#ccc', textDecoration: 'none', fontSize: '1.2rem' }}
          >
          <FontAwesomeIcon
            icon={faMapMarkerAlt}
            style={{
              fontSize: '50px',
              color: '#4f46e5',
              marginBottom: '20px',
            }}
          />
          <h3 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '10px', color: '#333' }}>
            Address
          </h3>

            <p style={{ fontSize: '1.2rem', color: '#555', lineHeight: '1.6' }}>
              P.O. Box 8885, Fujairah, UAE
            </p>
          </a>
        </div>

        {/* License Number Card */}
        <div
          style={{
            backgroundColor: '#ffffff',
            borderRadius: '10px',
            padding: '30px',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
            textAlign: 'center',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'scale(1.05)';
            e.currentTarget.style.boxShadow = '0 6px 15px rgba(0, 0, 0, 0.15)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'scale(1)';
            e.currentTarget.style.boxShadow = '0 4px 10px rgba(0, 0, 0, 0.1)';
          }}
        >
          <FontAwesomeIcon
            icon={faIdCard}
            style={{
              fontSize: '50px',
              color: '#f97316',
              marginBottom: '20px',
            }}
          />
          <h3 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '10px', color: '#333' }}>
            License Number
          </h3>
          <p style={{ fontSize: '1.2rem', color: '#555', lineHeight: '1.6' }}>1022822</p>
        </div>
      </div>

        {/* Federal Registration Card (Centered while keeping the size) */}
        <div className="row justify-content-center mt-4">
               <div
                 className="col-md-6"
                 style={{
                   backgroundColor: '#ffffff',
                   borderRadius: '10px',
                   padding: '30px',
                   boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                   textAlign: 'center',
                   transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                 }}
                 onMouseEnter={(e) => {
                   e.currentTarget.style.transform = 'scale(1.05)';
                   e.currentTarget.style.boxShadow = '0 6px 15px rgba(0, 0, 0, 0.15)';
                 }}
                 onMouseLeave={(e) => {
                   e.currentTarget.style.transform = 'scale(1)';
                   e.currentTarget.style.boxShadow = '0 4px 10px rgba(0, 0, 0, 0.1)';
                 }}
               >
                 <FontAwesomeIcon
                   icon={faBuilding}
                   style={{
                     fontSize: '50px',
                     color: '#f97316',
                     marginBottom: '20px',
                   }}
                 />
                 <h3 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '10px', color: '#333' }}>
                   Federal Registration
                 </h3>
                 <p style={{ fontSize: '1.2rem', color: '#555', lineHeight: '1.6' }}>12070823</p>
               </div>
             </div>
           </div>
         </section>
       );

export default Contact;
